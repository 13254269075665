/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-20 10:44:07
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 01:02:23
 */
import {
  Order,
  Com
} from '@/api/index';
import {
  dealUrlHttp,
  showLayer,
  isHasLogin
} from '@/utils/com';

//获取订单列表
export async function getOrder(data) {
  let res = await Order.orderList(data);
  return res
}

//取消订单
export async function cancelOrder(data) {
  let res = await Order.cancelOrder(data);
  return res
}
// 开票
export async function createBill(data){
  let res = await Order.createBill(data)
  return res;
}
// 抬头列表
export async function getBillHeader(data){
  let res = await Order.getBillHeader(data)
  return res;
}
//确认收货
export async function sureOrder(data){
  let res =await Order.sureOrder(data)
  return res
}

export async function orderReturn(data){
  let res = await Order.orderReturn(data);
  return res;
}

export async function upFileCom(data) {
  let res = await Com.upFileCom(data)
  return res;
}
