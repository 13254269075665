<!--
 * @Description: 我的订单
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-18 18:45:15
 * @LastEditors: zmj
 * @LastEditTime: 2022-04-06 11:58:52
-->
<template>
  <div class="w ww my_index">
    <div class="pr">
      <div class="my_order_rt" style="width:100%">
        <div class="tab">
          <ul class="zx_t">
            <li
              :class="{ active: v.val == selectState }"
              v-for="(v, i) in stateList"
              :key="i"
              @click="searchState(v.val)"
            >
              {{ v.label
              }}<span v-if="i == 0 || v.count">（{{ v.count }}）</span><i></i>
            </li>
          </ul>
          <div class="my_order_rt_c">
            <div>
              <table>
                <tr>
                  <th></th>
                  <th>{{this.$t('productInfo')}}</th>
                  <th></th>
                  <th>{{this.$t('unitPrice1')}}</th>
                  <th>{{this.$t('quantity')}}</th>
                  <th>{{this.$t('total')}}</th>
                  <th>{{this.$t('state')}}</th>
                  <th>{{this.$t('operation')}}</th>
                </tr>
              </table>
            </div>
            <ul class="zx_c" v-if="orderList.length > 0">
              <li class="thisclass">
                <table v-for="(v, i) in orderList" :key="i">
                  <tr>
                    <td colspan="8">
                      {{
                        v.createtime
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;订单号：{{
                        v.orderCode
                      }}
                      <div class="orderRealAmount">应付金额：<span >￥{{v.orderRealAmount}}</span></div>
                      <div class="orderRealAmount">折扣价:<span >￥{{v.orderDiscount}}</span></div>
                      <div class="orderRealAmount">总金额：<span >￥{{v.orderTotal}}</span></div>
                    </td>
                  </tr>
                  <tr
                    v-for="(x, y) in v.orderItemList"
                    :key="y"
                    class="pro-tr"
                  >
                    <td><img :src="IMGURL+x.productPic" /></td>
                    <td>
                      <h3>{{ x.productName || "" }}</h3>
                      <p>{{ x.secondName || "" }}</p>
                    </td>
                    <td>{{ x.categoryName }}-{{ x.specsName }}</td>
                    <td>
                      <div class="gwc_tab_1">
                        ￥{{ x.itemPrice }}
                      </div>
                    </td>
                    <td>×{{ x.itemCount }}</td>
                    <td>￥{{ x.itemTotal }}</td>
                    <td v-if="v.state == 0"> 待付款 </td>
                     <td v-if="v.state == 3"> 已付款 </td>
                    <td v-if="v.state == 1"> 待发货 </td>
                    <td v-if="v.state == 8"> 已取消</td>
                    <td v-if="v.state == 5"> <a @click="viewLogistics(v.orderCode)">查看物流</a> </td>
                    <td :rowspan="v.orderItemList.length || 0" v-if="y == 0">
                      <div class="my_order_rt_1" v-if="v.state == 1">
                        <a>催发货</a>
                        <a @click.stop="canclOrder(v.owid, i)">取消订单</a>
                      </div>
                      <div class="my_order_rt_1" v-if="v.state == 0">
                        <a @click.stop="toPay(v)">去支付</a
                        ><a @click.stop="canclOrder(v.owid, i)">取消订单</a>
                      </div>
                      <div class="my_order_rt_1" v-if="v.state == 8">
                        <span>已取消</span>
                      </div>
                      <div class="my_order_rt_1" v-if="v.state == 5">
                        <a @click.stop="kaipiao(v.owid, i)">申请开票</a>
                        <a @click.stop="refund(v.owid)">申请退款</a>
                      </div>
                      <div class="my_order_rt_1" v-if="v.state == 3">
                        <a @click.stop="sureReceiving(v.owid)">确认收货</a
                        ><a @click.stop="kaipiao(v.owid, i)">申请开票</a>
                      </div>
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
            <NullTip tip="暂无订单" v-if="orderList.length == 0"></NullTip>
          </div>
          <Pagination
            :totalPage="totalPage"
            :currentPage="currentPage"
            @pageFun="goPageSrh"
          />
        </div>
      </div>
    </div>
    <el-dialog title="请选择发票抬头" :visible.sync="dialogVisible" width="50%" >
      <el-form >
        <el-form-item label="发票抬头:">
    <el-select v-model="orderParams.studyRefOwid" placeholder="请选择发票抬头" >
      <el-option  v-for="(item,index) in header" :key="index" :label="item? item.taxCode+'-'+item.billContent:'抱歉,您还没有创建发票抬头'" :value="item.owid"></el-option>
    </el-select>
  </el-form-item>
      </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取消</el-button>
    <el-button type="primary"  @click="sure">确定</el-button>
  </span>
</el-dialog>
<el-dialog
  title="退货申请"
  :visible.sync="returnRequest"
  width="30%"
  >
  <el-form ref="form" :model="form" label-width="90px">
    <el-form-item label="退货原因：">
      <el-input type="textarea" v-model="form.returnMemo" size="medium"></el-input>
  </el-form-item>
  <el-form-item label="上传图片：">
      <div class="picture-list" v-for="(item,index) in fileList" :key="index">
          <img :src="IMGURL+item" alt="">
      </div>
      <div class="update">
        <!-- <span>点击上传</span>  -->
        <input type="file" id="upfile-img" class="upfile-img" ref="clearFile" @change="uploadImg($event)" multiple>
      </div>
  </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="returnRequest = false">取 消</el-button>
    <el-button type="primary" @click="commitApply">提交</el-button>
  </span>
</el-dialog>
  <!-- 物流查询 -->
  <el-dialog
  title="物流信息"
  :visible.sync="logisticsShow"
  width="40%"
  >
   <div class="w logistics-query" v-if="logisticsInfo">
    <el-timeline>
    <el-timeline-item
      v-for="(item, index) in logisticsInfo"
      :key="index"
      :type="item.type"
      :size="item.size"
      :timestamp="item.time">
      {{item.context}}
    </el-timeline-item>
  </el-timeline>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="logisticsShow = false">取 消</el-button>
    <el-button type="primary" @click="logisticsShow = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { getOrder, cancelOrder ,createBill,getBillHeader,sureOrder,orderReturn,upFileCom} from "./model";
import { mapState, mapActions, mapMutations } from "vuex";
import {expressQuery} from '../../logistics/model'
import Pagination from "@/components/component/Pagination.vue";
import NullTip from "@/components/component/NullTip.vue";
import { IMGURL } from "@/utils/constant";
import { showLayer,upFile } from "@/utils/com";
export default {
  data() {
    return {
      IMGURL,
      orderList: [],
      filter: "", //查询str
      totalCount: 0,
      totalPage: 0,
      currentPage: 1,
      selectState: -1,
      stateList: [
        { label: "全部订单", val: -1, count: 0 },
        { label: "待付款", val: 0, count: 0 },
        { label: "待发货", val: 1, count: 0 },
        { label: "待收货", val: 4, count: 0 },
      ],
       invoiceOrder:'',
       orderParams:{
         billHeader: '',
         applyMan:'',
         applyTime:'',
         orderRefOwid:'',
         isBill:1,
         studyRefOwid:'',
       },
       dialogVisible:false, //选择发票抬头对话框
       header:[],//发票抬头列表,
       returnRequest:false,
       
      //  退货申请
       form:{
         returnMemo:'',
          fileExtId:'',
         orderRefOwid:'',//订单主键
       },
       upfiles:'',
       fileList:[],
       logisticsInfo:[],
       logisticsShow:false
      
    };
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapMutations("product", ["setPayRs"]),
    async getList(pageNo = 1) {
      let res = await getOrder({
        pageNo,
        pageSize: 6,
        state: this.selectState,
        filter: this.filter,
      });
      if (res) {
        res.records ? (this.orderList = res.records) : (this.orderList = []);
        this.totalPage = res.totalPage;
        this.currentPage = res.currentPage;
        this.selectState == -1 && (this.stateList[0].count = res.totalCount);
        
      }
    },

    //状态查询订单
    searchState(state) {
      this.selectState = state;
      this.getList();
    },

    orderDt(id, index) {},

    kaipiao(id, index) {
      this.dialogVisible =true;
      // 保存请求过来的发票抬头
        getBillHeader().then(res =>{
            this.header = res;
        })
       
      for(let i =0;i<this.orderList.length;i++){
        this.invoiceOrder=this.orderList[index];
      }
     this.orderParams.applyMan = this.invoiceOrder.recName; 
     this.orderParams.applyTime = this.invoiceOrder.createtime; //申请时间
     this.orderParams.billHeader = this.invoiceOrder.billType;
     this.orderParams.orderRefOwid = this.invoiceOrder.owid;  //订单主键
    },
    sure(){
      this.dialogVisible = false
      if(this.orderParams.studyRefOwid){
        createBill(this.orderParams).then(res =>{
          console.log(res);
      })
      }
    },
    //分页
    goPageSrh(pageNo) {
      this.getList(pageNo);
    },

    //取消订单
    async canclOrder(owid, index) {
      let res = await cancelOrder({ owid });
      if (res) {
        this.orderList[index].state = 8;
        showLayer("success", "取消成功！");
      }
    },

    //立即付款
    toPay(res) {
      // this.setPayRs({ payResult: res });
      this.$router.push({path:'/pay',query:{info:JSON.stringify(res)}});
    },

    // //前往产品详情
    // goPoDetail(id) {
    //   this.$router.push({ path: "/productdetails", query: { proId: id } });
    // },
    //确认收货
    sureReceiving(owid){
      sureOrder({owid:owid}).then(res =>{
        if(res === '确认订单成功'){
          showLayer("success", "确认订单成功！");
        }
      })
    },
    // 申请退款
    refund(owid){
      this.returnRequest = true
      this.form.orderRefOwid = owid;
    },
    //上传图片
    uploadImg(e){
      this.upfiles = upFile(e)[0]
          upFileCom({file: this.upfiles,data:JSON.stringify({fileClass:'oasis',fileExtId:this.form.fileExtId})}).then(res=>{
            this.fileList.push(res.filePath)
            if(this.form.fileExtId == ''){
              this.form.fileExtId = res.fileExtId
            }
          })
    },
    // 提交申请
    commitApply(){
      orderReturn(this.form).then(res=>{
        if(res){
          showLayer("success", "申请提交成功！");
          this.returnRequest = false;
        }
      })
    },
    // 查看物流
    viewLogistics(orderCode){
      expressQuery({orderCode}).then(res=>{
        this.logisticsShow = true
        this.logisticsInfo = res.data;
      })
    }
  },
  components: {
    Pagination,
    NullTip,
  },
};
</script>
<style scoped>
.orderRealAmount {
  float: right;
  margin-right: 0.2rem;
}
.orderRealAmount span {
   color: #f55050;
}

</style>

